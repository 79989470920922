.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: #990000; */
    background-color: white;
}

.secretsanta, .createpool, .profile, .login {
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    padding: 14px 25px;
    display: inline-block;
    text-align: center;
    /* color: white; */
    color: #ff0505;
}

.navbar > a:hover {
    background-color: #ff0505;
    color: white;
}

.Burger {
    display: none;
}

@media only screen and (max-width: 520px) {
    .Burger {
        display: block;
    }
}

