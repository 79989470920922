@import url(https://fonts.googleapis.com/css?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css?family=Acme&display=swap);
@import url(https://fonts.googleapis.com/css?family=Acme&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ff0505;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  color: white;
  font-family: 'Lobster', cursive;
}

main {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}
.header {
    font-size: 40px;
    margin-bottom: 10px;
}

.poolForm {
    display: flex;
    flex-direction: column;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.poolName {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
}

.poolName > label {
    font-size: 22px;
}

.poolName > input {
    width: 25%;
    margin-left: auto;
    margin-right: auto;
    padding: 8px;
    font-weight: bold;
}

.formInput {
    margin-top: 4px;
    margin-bottom: 8px;
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
}

#name, #email {
    width: 33%;
    padding: 10px;
}

.removeButton {
    padding: 4px;
}

input{
    margin-left: 5px;
}

.getPairsButton {
    width: 50%;
}

.bottomBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}


@media only screen and (max-width: 1350px) {
    .poolForm {
        width: 55%;
    }
}

@media only screen and (max-width: 960px) {
    .poolForm {
        width: 65%;
    }
}

@media only screen and (max-width: 760px) {
    .poolForm {
        width: 75%;
    }
}

@media only screen and (max-width: 630px) {
    .poolForm {
        width: 75%;
    }

    .poolName > input {
        width: 40%;
    }

    .formInput {
        justify-content: flex-start;
        padding-left: 10px;
    }

    #name, #email {
        width: 66%;
    }

    #email {
        margin-left: 10px;
    }

    .removeButton {
        margin-left: 20px;
    }
}

@media only screen and (max-width: 520px) {
    .poolForm {
        width: 90%;
    }

    .poolName > input {
        width: 50%
    }

    .formInput {
        padding-top: 10px;
    }

}

@media only screen and (max-width: 400px) {

    .formInput {
        align-items: center;
    }

    #email, #name {
        width: 60%;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    #email {
        margin-left: 10px;
    }
}
.signupSection {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: red;
    width: 33%;
    margin-top: 50px;
    padding-bottom: 75px;
}

.loginSection > h1 {
    margin-top: 30px;
    margin-bottom: 50px;
}

.signupForm {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.signupForm > input {
    width: 100%;
    margin: 4px 0;
    padding: 4px;
    background-color: lightgray;
}

.signupBtn {
    width: 25%;
    padding: 4px;
    margin: 10px 0;
    font-weight: bold;   
}

.signupBtn:hover {
    background-color: red;
    color: white;
}

.signupForm > label {
    font-weight: bold;
    font-size: 18px;
}

@media only screen and (max-width: 1230px) {

    .signupSection {
        width: 45%;
    }

}

@media only screen and (max-width: 740px) {
    .signupSection {
        width: 50%;
    }

    .signupBtn  {
        width: 45%;
    }
}

@media only screen and (max-width: 550px) {
    .signupSection {
        width: 65%;
    }
}

@media only screen and (max-width: 400px) {
    .signupSection {
        width: 75%;
        margin-top: 75px;
    }
}

.profileSection {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    width: 90%;
}

.profileHeader { 
    width: 100%;
    font-size: 36px;
}

.interestSection, .pairSection, .poolSection {
    width: 30%;
    background-color: white;
    color: red;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Acme', sans-serif;
    font-size: 20px;
}

.addInterests {
    padding: 4px;
    margin-right: 3px;
    background-color: lightgray;
}

.addInterestBtn{
    border: 1px solid #aaa;
    box-shadow: inset 1px 1px 3px #fff;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    position: relative;
    margin: 4px;
    display: inline-block;
    vertical-align: middle;
    background: #aaaaaa4f;
}
.addInterestBtn:hover{
    background: radial-gradient(#aaa, #fff);
}
.addInterestBtn:active{
    background: #6363634f;
}
.addInterestBtn:before,
.addInterestBtn:after{
    content:'';position:absolute;top:0;left:0;right:0;bottom:0;
}

.addInterestBtn.plus:before,
.addInterestBtn.plus:after {
    background:green;
    box-shadow: 1px 1px 1px #ffffff9e;
}
.addInterestBtn.plus:before{
    width: 2px;
    margin: 3px auto;
}
.addInterestBtn.plus:after{
    margin: auto 3px;
    height: 2px;
    box-shadow: none;
}

.userInterests {
    list-style-type: none;
    padding: 0;
}

.userInterests > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 75%;
}
.interestSection > form > label {
    font-weight: bold;
}

.interestSection > form {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.pairSection > h3 {
    padding-left: 10px;
    padding-right: 10px;
}

.poolSection > ul, .pairSection > ul {
    list-style-type: none;
    padding: 0;
}

a {
    text-decoration: none;
    color: red;
    font-size: 24px;
}

a:hover {
    color: black;
}

.pairSection > h3, .interestSection > h3, .poolSection > h3 {
    text-decoration: underline;
}

@media only screen and (max-width: 1170px) {
    .interestSection, .pairSection, .poolSection {
        width: 45%;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 830px) {
    .interestSection, .pairSection, .poolSection {
        width: 55%;
    }
}

@media only screen and (max-width: 640px) {
    .interestSection, .pairSection, .poolSection {
        width: 70%;
    }
}

@media only screen and (max-width: 500px) {
    .interestSection, .pairSection, .poolSection {
        width: 90%;
    }
} 
.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    /* background-color: #990000; */
    background-color: white;
}

.secretsanta, .createpool, .profile, .login {
    font-size: 22px;
    font-weight: bold;
    text-decoration: none;
    padding: 14px 25px;
    display: inline-block;
    text-align: center;
    /* color: white; */
    color: #ff0505;
}

.navbar > a:hover {
    background-color: #ff0505;
    color: white;
}

.Burger {
    display: none;
}

@media only screen and (max-width: 520px) {
    .Burger {
        display: block;
    }
}


.burger-menu,
.burger-menu.open {
  display: inline-block;
  cursor: pointer;
  position: fixed;
  right: 3px;
  top: 3px;
  z-index: 9999;
  background: #fff;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
}

.burger-menu .bar1,
.bar2,
.bar3 {
  width: 25px;
  height: 3px;
  background-color: #ff0505;
  margin: 4px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}


.mobileNav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
}

.secretsanta, .createpool, .profile, .login {
    display: inline-block;
    color: #ff0505;
}

.mobileNav > a:hover {
    background-color: #ff0505;
    color: white;
}
.pairsList > ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
}

.pairsList {
    font-size: 22px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}

.pairsList > ul > li {
    border: 1px solid white;
    padding: 15px;
    margin: 10px;
    width: 25%;
    font-family: 'Acme', sans-serif;
}

@media only screen and (max-width: 670px) {
    .pairsList {
        width: 90%;
    }
}

@media only screen and (max-width: 550px) {

    .pairsList > ul {
        margin-top: 50px;
    }

    .pairsList > ul > li {
        width: 35%;
    }
}
.loginSection {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: red;
    width: 33%;
    margin-top: 50px;
    padding-bottom: 75px;
}

.loginSection > h1 {
    margin-top: 30px;
    margin-bottom: 50px;
}

.loginForm {
    width:75%;
    margin-left: auto;
    margin-right: auto;
}

.loginForm > input {
    width: 100%;
    margin: 4px 0;
    padding: 4px;
    background-color: lightgray;
}

.loginBtn {
    width: 25%;
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: bold;
}

.loginBtn:hover {
    background-color: red;
    color: white;
}

.loginForm > label {
    font-weight: bold;
    font-size: 18px;
}

@media only screen and (max-width: 1230px) {

    .loginSection {
        width: 45%;
    }

}

@media only screen and (max-width: 740px) {
    .loginSection {
        width: 50%;
    }

    .loginBtn {
        width: 45%;
    }
}

@media only screen and (max-width: 550px) {
    .loginSection {
        width: 65%;
    }
}

@media only screen and (max-width: 400px) {
    .loginSection {
        width: 75%;
        margin-top: 75px;
    }
}
.demo {
    font-family: 'Acme', sans-serif;
    font-size: 18px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

ul {
    list-style-type: none;
}
.mainSelection {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.createpoolBox, .signup {
    font-size: 26px;
    font-weight: bold;
    text-decoration: none;
    padding: 200px 100px;
    width: 100px;
    display: inline-block;
    text-align: center;
    color: #ff0505;
    background-color: white;
}

.createpoolBox:hover, .signup:hover {
    /* background-color: rgb(85, 0, 0); */
    background-color: #850000;
    color: white;
}

.createpoolBox {
    margin-right: 5px;
}

.signup {
    margin-left: 5px;
}



@media only screen and (max-width: 620px) {
    .createpoolBox {
        margin-left: 5px;
        margin-bottom: 5px;
        padding: 150px 100px;
    }

    .signup {
        margin-right: 5px;
        margin-top: 5px;
        margin-bottom: 10px;
        padding: 150px 100px;
    }
}
.snowflake {
  color: #fff;
  font-size: 1em;
  font-family: Arial, sans-serif;
  text-shadow: 0 0 5px #000; }

@-webkit-keyframes snowflakes-fall {
  0% {
    top: -10%; }
  100% {
    top: 100%; } }

@-webkit-keyframes snowflakes-shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  50% {
    -webkit-transform: translateX(80px);
    transform: translateX(80px); } }

@keyframes snowflakes-fall {
  0% {
    top: -10%; }
  100% {
    top: 100%; } }

@keyframes snowflakes-shake {
  0%, 100% {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  50% {
    -webkit-transform: translateX(80px);
            transform: translateX(80px); } }

.snowflake {
  position: fixed;
  top: -10%;
  z-index: 9999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
  -webkit-animation-name: snowflakes-fall,snowflakes-shake;
  -webkit-animation-duration: 10s,3s;
  -webkit-animation-timing-function: linear,ease-in-out;
  -webkit-animation-iteration-count: infinite,infinite;
  -webkit-animation-play-state: running,running;
  animation-name: snowflakes-fall,snowflakes-shake;
  animation-duration: 10s,3s;
  animation-timing-function: linear,ease-in-out;
  animation-iteration-count: infinite,infinite;
  animation-play-state: running,running; }

.snowflake:nth-of-type(0) {
  left: 1%;
  -webkit-animation-delay: 0s,0s;
  animation-delay: 0s,0s; }

.snowflake:nth-of-type(1) {
  left: 10%;
  -webkit-animation-delay: 1s,1s;
  animation-delay: 1s,1s; }

.snowflake:nth-of-type(2) {
  left: 20%;
  -webkit-animation-delay: 6s,.5s;
  animation-delay: 6s,.5s; }

.snowflake:nth-of-type(3) {
  left: 30%;
  -webkit-animation-delay: 4s,2s;
  animation-delay: 4s,2s; }

.snowflake:nth-of-type(4) {
  left: 40%;
  -webkit-animation-delay: 2s,2s;
  animation-delay: 2s,2s; }

.snowflake:nth-of-type(5) {
  left: 50%;
  -webkit-animation-delay: 8s,3s;
  animation-delay: 8s,3s; }

.snowflake:nth-of-type(6) {
  left: 60%;
  -webkit-animation-delay: 6s,2s;
  animation-delay: 6s,2s; }

.snowflake:nth-of-type(7) {
  left: 70%;
  -webkit-animation-delay: 2.5s,1s;
  animation-delay: 2.5s,1s; }

.snowflake:nth-of-type(8) {
  left: 80%;
  -webkit-animation-delay: 1s,0s;
  animation-delay: 1s,0s; }

.snowflake:nth-of-type(9) {
  left: 90%;
  -webkit-animation-delay: 3s,1.5s;
  animation-delay: 3s,1.5s; }

.snowflake:nth-of-type(10) {
  left: 25%;
  -webkit-animation-delay: 2s,0s;
  animation-delay: 2s,0s; }

.snowflake:nth-of-type(11) {
  left: 65%;
  -webkit-animation-delay: 4s,2.5s;
  animation-delay: 4s,2.5s; }

.snowflake:nth-of-type(12) {
  left: 15%;
  -webkit-animation-delay: 1s,1s;
  animation-delay: 1s,1s; }

.snowflake:nth-of-type(13) {
  left: 25%;
  -webkit-animation-delay: 4s,2s;
  animation-delay: 4s,2s; }

.snowflake:nth-of-type(14) {
  left: 35%;
  -webkit-animation-delay: 8s,3.5s;
  animation-delay: 8s,3.5s; }

.snowflake:nth-of-type(15) {
  left: 55%;
  -webkit-animation-delay: 2.5s,1s;
  animation-delay: 2.5s,1s; }

.snowflake:nth-of-type(16) {
  left: 65%;
  -webkit-animation-delay: 1.5s,1s;
  animation-delay: 1.5s,1s; }

.snowflake:nth-of-type(17) {
  left: 75%;
  -webkit-animation-delay: 6s,2.5s;
  animation-delay: 6s,2.5s; }

.snowflake:nth-of-type(18) {
  left: 85%;
  -webkit-animation-delay: 3.6s,2s;
  animation-delay: 3.6s,2s; }

.snowflake:nth-of-type(19) {
  left: 33%;
  -webkit-animation-delay: 7s,3s;
  animation-delay: 7s,3s; }

