@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');

.App {
  text-align: center;
  color: white;
  font-family: 'Lobster', cursive;
}

main {
  display: flex;
  flex-direction: column;
}

button {
  cursor: pointer;
}