.loginSection {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    color: red;
    width: 33%;
    margin-top: 50px;
    padding-bottom: 75px;
}

.loginSection > h1 {
    margin-top: 30px;
    margin-bottom: 50px;
}

.loginForm {
    width:75%;
    margin-left: auto;
    margin-right: auto;
}

.loginForm > input {
    width: 100%;
    margin: 4px 0;
    padding: 4px;
    background-color: lightgray;
}

.loginBtn {
    width: 25%;
    padding: 4px;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: bold;
}

.loginBtn:hover {
    background-color: red;
    color: white;
}

.loginForm > label {
    font-weight: bold;
    font-size: 18px;
}

@media only screen and (max-width: 1230px) {

    .loginSection {
        width: 45%;
    }

}

@media only screen and (max-width: 740px) {
    .loginSection {
        width: 50%;
    }

    .loginBtn {
        width: 45%;
    }
}

@media only screen and (max-width: 550px) {
    .loginSection {
        width: 65%;
    }
}

@media only screen and (max-width: 400px) {
    .loginSection {
        width: 75%;
        margin-top: 75px;
    }
}