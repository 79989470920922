@import url('https://fonts.googleapis.com/css?family=Acme&display=swap'); 

.demo {
    font-family: 'Acme', sans-serif;
    font-size: 18px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

ul {
    list-style-type: none;
}